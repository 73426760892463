import React from 'react';
import AgentComponent from './AgentApiComponent';


function AgentsPage() {
  return (
    <div>
      <AgentComponent />
    </div>
  );
}

export default AgentsPage;