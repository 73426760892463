import React from 'react';
import MapApi from "./MapApiComponent"

function MapsPage() {
  return (
    <div>
      <MapApi />
    </div>
  );
}

export default MapsPage;